<template>

	<div class="cont" id="p24">
		<div class="c-list">
			<div class="c01">
				<div class="c-title">공지사항</div>
				<div class="search-bar">
					<input type="text" placeholder="검색"
						v-model="search.keyword"
					>
					<a @click="getData"><i class="xi-search"></i></a>
				</div>
			</div>
			<div class="c02">
				<div class="board">
					<div class="board-header">
						<div class="board-item">
							<div class="td">제목</div>
							<div class="td">등록일</div>
						</div>
					</div>
					<div class="board-body">
						<div class="board-item"
							v-for="(item, index) in items"
							:key="index"
							@click="goDetail(item.bbsSeq)"
							>
							<div class="td" style="padding-left: 10px;">{{ item.title }}<span class="new" :class="{ active: item.active}">new</span></div>
							<div class="td">{{ item.createAt.substring(0, 8) | transDate }}</div>
						</div>
						<div
							v-if="!items.length"
							class="no-data" style="border-bottom: 1px solid #ddd;"
						>
							No Data
						</div>
					</div>
				</div>

				<div class="text-center mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	
	import Pagination from '@/components/Pagination'
	
	export default{
		name: 'NoticeList'
		,props: ['user']
		,components: { Pagination }
		,data: function(){
			return {
				items: [
				]
				,item: {
				}
				,search: {
					keyword: ''
				}
				,showPopup: {
					detail: false
				}
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: 'notices'
						,authorize: true
						,data: this.search
					})
					if(result.success){
						this.items = result.data.content.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,setOverlay: function(type){
				this.$emit('setOverlay', type)
			}
			,goDetail: async function(bbsSeq){
				if(!bbsSeq){
					this.$emit('setNotify', { type: 'error', message: '공지사항 정보가 없습니다'})
					return false
				}
				this.$router.push({ name: 'NoticeDetail', params: { bbsSeq: bbsSeq }})
			}
		}
		,created: function(){
			this.getData()
		}
	}
</script>











